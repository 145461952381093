<template>
	<div class="page-container">
		<Toast />
		<div>
			<div
				class="spinner-container"
				v-if="isLoading">
				<ProgressSpinner
					style="width: 50px; height: 50px"
					strokeWidth="5"
					fill="var(--surface-ground)"
					animationDuration=".5s"
					aria-label="Custom ProgressSpinner" />
			</div>
			<div
				class="spinner-container"
				v-if="generationLaoding">
				<ProgressSpinner
					style="width: 50px; height: 50px"
					strokeWidth="5"
					fill="var(--surface-ground)"
					animationDuration=".5s"
					aria-label="Custom ProgressSpinner" />
				<br />
				<div class="generation-text mx-3">
					<span>Votre attestation est en cours de génération.</span> <br />
					Veuillez patienter pour le télécharger.
				</div>
			</div>
			<div class="title-padding m-0">
				<div
					@click="$router.go(-1)"
					class="flex arrow_back_div mb-4">
					<span class="material-icons arrow_back_class mr-2">arrow_back</span>
					<span class="form-header">Retour</span>
				</div>
				<h1 class="espace-header">Déclaration d'une naissance</h1>
			</div>
			<div class="form-header">
				Ce formulaire doit être rempli par un professionnel de la santé affilié
				à la télédéclaration
			</div>
			<div v-if="!submitted">
				<form @submit="onSubmit">
				<div class="form-section">
					<div class="section-header">Informations du centre de santé</div>
					<div
						class="formgrid grid mx-2"
						style="margin-top: 2rem">
						<div class="field col">
							<PInput
								label="Centre de santé"
								:value="useAuthStore().getUserInfo.health_center"
								disabled />
							<div class="field grid px-2">
								<label
									for="accoucheuse"
									class="col-12 mb-2 md:col md:mb-0"
									>Accoucheur (se)</label
								>
								<div class="col-12 md:col" style="text-align: end">
									<AutoComplete class="w-full" placeholder="Selectionnez..." id="id" :dropdown="true" :multiple="false" v-model="midwife_select" :suggestions="autoFilteredValue" @complete="searchUser($event)" field="name" />
									<small
										id="type-help"
										class="p-error ">
										{{ errors.midwife }}
									</small>
								</div>
							</div>
						</div>
						<div class="field col">
							<div class="field grid px-2">
								<label
									for="declarant"
									class="col-12 mb-2 md:col md:mb-0"
									>Déclarant</label
								>
								<div class="col-12 md:col" style="text-align: end">
									<AutoComplete class="w-full" placeholder="Selectionnez..." id="value" :dropdown="true" :multiple="false" v-model="declarant_select" :suggestions="autoFilteredValue" @complete="searchUser($event)" field="name" />
									<small
										id="type-help"
										class="p-error ">
										{{ errors.declarant }}
									</small>
								</div>
							</div>
							<PInput
								label="Code secret"
								autocomplete="off"
								:value="secret_code"
								v-model="secret_code"
								:class="{ 'p-invalid': errors.secret_code }"
								field="secret_code" />
						</div>
					</div>
				</div>
				<div class="form-section">
					<div class="section-header">Informations sur la naissance</div>
					<div
						class="formgrid grid mx-2"
						style="margin-top: 2rem">
						<div class="field col">
							<PInput
								label="Nom de la mère"
								:value="mother_last_name"
								v-model="mother_last_name"
								:class="{ 'p-invalid': errors.mother_last_name }"
								field="mother_last_name" />
							<PInput
								label="Téléphone de la mère"
								:value="mother_phone_number"
								v-model="mother_phone_number"
								:class="{ 'p-invalid': errors.mother_phone_number }"
								field="mother_phone_number" />
							<PInput
								label="Autre contact 1"
								:value="other_contact_1"
								v-model="other_contact_1"
								:class="{ 'p-invalid': errors.other_contact_1 }"
								field="other_contact_1" />
							<PInput
								label="Autre contact 2"
								:value="other_contact_2"
								v-model="other_contact_2"
								:class="{ 'p-invalid': errors.other_contact_2 }"
								field="other_contact_2" />
							<PInput
								label="Quartier de la mère"
								:value="neighborhood"
								v-model="neighborhood"
								:class="{ 'p-invalid': errors.neighborhood }"
								field="neighborhood" />
						</div>
						<div class="field col">
							<PInput
								label="Prénoms de la mère"
								:value="mother_first_name"
								v-model="mother_first_name"
								:class="{ 'p-invalid': errors.mother_first_name }"
								field="mother_first_name" />
							<div class="field grid px-2 py-0">
								<label
									for="date"
									class="col-12 mb-2 md:col md:mb-0"
									>Date d'accouchement</label
								>
								<div class="col-12 md:col" style="text-align: end">
									<Calendar
										class="w-full"
										showIcon
										v-model="birthdate"
										:minDate="minDate"
										:maxDate="maxDate"
										:showOnFocus="false"
										dateFormat="dd/mm/yy" />
									<small
										id="type-help"
										class="p-error ">
										{{ errors.birthdate }}
									</small>
								</div>
							</div>
							<PInput
								label="Heure d'accouchement"
								type="time"
								:value="birth_hour"
								v-model="birth_hour" />
							<div class="flex py-0 my-0" style="justify-content: end;">
								<small
									id="type-help"
									class="p-error ">
									{{ errors.birth_hour }}
								</small>
							</div>
							<div class="field grid px-2 py-1">
								<label
									for="sexe"
									class="col-12 mb-2 md:col md:mb-0"
									>Sexe de l'enfant</label
								>
								<div class="col-12 md:col flex flex-wrap gap-3" style="justify-content: end;">
									<div class="flex">
										<RadioButton
											inputId="male"
											name="Masculin"
											value="male"
											v-model="sex" />
										<label
											for="male"
											class="ml-2"
											>Masculin</label
										>
									</div>
									<div class="flex">
										<RadioButton
											inputId="female"
											name="Feminin"
											value="female"
											v-model="sex" />
										<label
											for="female"
											class="ml-2"
											>Feminin</label
										>
									</div>
									<small
										id="type-help"
										class="p-error ">
										{{ errors.sex }}
									</small>
								</div>
							</div>
							<PInput
								label="Adresse de la mère"
								:value="address"
								v-model="address"
								:class="{ 'p-invalid': errors.address }"
								field="address" />
						</div>
					</div>
				</div>
				<div class="my-4 send-declaration-btn-container">
					<Button
						class="send-declaration-btn"
						label="Ajouter"
						type="submit" />
				</div>
			</form>
			</div>
			<div v-if="submitted">
				<div class="mt-4">
					<button
						class="files-btn-class mr-2"
						@click="goToHome(true)">
						Accueil
					</button>
					<button
						class="files-btn-class"
						@click="goToHome(false)">
						Déclarer une autre naissance
					</button>
				</div>
			</div>
			<div
				class="bg-default-green tracked_code_container"
				v-if="submitted">
				<div
					class="text-white tracked_text"
					v-if="!generationLaoding">
					Votre déclaration est enregistrée sous le code :
					<span class="code-color">{{ tracked_code }}</span> .
				</div>
				<div
					class="mt-4 send-declaration-btn-container"
					v-if="!generationLaoding">
					<button
						v-if="media_url"
						class="send-declaration-btn"
						@click="openUrl()">
						Télécharger l'attestation
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref, watch } from "vue";
	import { useForm } from "vee-validate";
	import * as yup from "yup";
	import { useRouter } from 'vue-router';
	import { useToast } from "primevue/usetoast";

	const toast = useToast()
	
	const schema = yup.object({
		midwife: yup
			.string()
			.required("Veuillez choisir l'accoucheur(se)")
			.label("Accoucheur (se)"),
		secret_code: yup
			.string()
			.required("Le code secret est requis")
			.label("Code secret"),
		declarant: yup
			.string()
			.required("Veuillez choisir le déclarant")
			.label("Déclarant"),
		mother_first_name: yup
			.string()
			.matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, {
				message: "Le champ ne doit contenir que des lettres"
			})
			.required("Le nom de la mère est requis")
			.label("Nom de la mère"),
		mother_last_name: yup
			.string()
			.matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, {
				message: "Le champ ne doit contenir que des lettres"
			})
			.required("Le prénom de la mère est requis")
			.label("Prénoms de la mère"),
		mother_phone_number: yup
			.string()
			.required("Le téléphone de la mère est requis")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("Téléphone de la mère"),
		other_contact_1: yup
			.string()
			.required("Veuillez renseigner un deuxieme numero")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("Autre contact 1"),
		birthdate: yup
			.string()
			.required("La date d'accouchement est requise")
			.label("Date d'accouchement"),
		birth_hour: yup
			.string()
			.required("L'heure d'accouchement est requise")
			.label("Heure d'accouchement"),
		sex: yup
			.string()
			.required("Veuillez choisir le sexe de l'enfant")
			.label("Sexe de l'enfant"),
		neighborhood: yup
			.string()
			.required("Veuillez renseigner le quartier de la mère")
			.label("Quartier de la mère"),
		address: yup
			.string()
			.required("Veuillez renseigner l'adresse de la mère")
			.label("Adresse de la mère"),
	});

	const { defineField, handleSubmit, errors } = useForm({
		validationSchema: schema,
	});

	const [midwife] = defineField("midwife");
	const [secret_code] = defineField("secret_code");
	const [declarant] = defineField("declarant");
	const [mother_first_name] = defineField("mother_first_name");
	const [mother_last_name] = defineField("mother_last_name");
	const [mother_phone_number] = defineField("mother_phone_number");
	const [other_contact_1] = defineField("other_contact_1");
	const [other_contact_2] = defineField("other_contact_2");
	const [birthdate] = defineField("birthdate");
	const [birth_hour] = defineField("birth_hour");
	const [sex] = defineField("sex");
	const [neighborhood] = defineField("neighborhood");
	const [address] = defineField("address");

	const midwife_select = ref({} as AgentOption);
	const declarant_select = ref({} as AgentOption);
	const submitted = ref(false);
	const isLoading = ref(false);
	const mediaName = ref(null);
	const generationLaoding = ref(false);
	const tracked_code = ref('');
	const media_url = ref('');
	const mediaUri = ref('');
	const router = useRouter();

	watch(
		() => midwife_select.value,
		(newVal) => {
			if(newVal?.id) midwife.value = newVal.id;
			else midwife.value = " "
		}
	);

	watch( 
		() => declarant_select.value,
		(newVal) => {
			declarant.value = newVal.id;
		}
	);

	const onSubmit = handleSubmit((values) => {
		if (
			isLoading.value ||
			new Date(values.birthdate) > new Date()
		) {
			toast.add({ severity: 'error', summary: 'Message', detail: 'Veuillez renseigner une date de naissance valide', life: 8000 });
			return;
		}
		isLoading.value = true;
		const birthDeclaration = values as BirthDeclaration;
		birthDeclaration.agent_function = 'Agent'
		birthDeclaration.other_contact_2 = values.other_contact_2 ? values.other_contact_2 : '';
		birthDeclaration.health_center = useAuthStore().getUserInfo.health_center;
		birthDeclaration.health_center_code = useAuthStore().getUserInfo.health_center_code;
		birthDeclaration.birthdate = dateMixin.methods.resetTimeToZero(values.birthdate);

		const array = [] as Array<BirthDeclaration>;
		array.push(birthDeclaration);
		useBirthStore().sendBirthDeclaration(array)
					.then((response) => {
						if (response) {
							submitted.value = true;
							tracked_code.value = response.tracked_code;
							generateDoc(response);
						}
					})
					.finally(() => {
						isLoading.value = false;
					});
	});

	const generateDoc = (response: BirthDeclaration) => {
		generationLaoding.value = true;
				const payload = {
					html_template_name: birthHtmlTemplate,
    				css_template_name: birthCssTemplate,
    				document_id: response.tracked_code,
					variables: {
						district: espacePartenaireStore().getHealthCenter.district,
						township: espacePartenaireStore().getHealthCenter.township,
						health_center_name: response.health_center,
						bp: espacePartenaireStore().getHealthCenter.postal_code,
						tracked_code: response.tracked_code,
						midwife: dataMixin.methods.agentName(response.midwife),
						function: dataMixin.methods.agentFunction(response.midwife),
						mother_lastname: response.mother_last_name,
						mother_firstname: response.mother_first_name,
						neighborhood: response.neighborhood,
						address: response.address,
						birth_date: dateMixin.methods.formatDate(response.birthdate),
						birth_hour: response.birth_hour,
						sex: response.sex == "male" ? "Masculin" : "Feminin",
						town: espacePartenaireStore().getHealthCenter.town,
						created_at: dateMixin.methods.formatDate(response.created_at),
						center_phone: espacePartenaireStore().getHealthCenter.mobile,
						service: dataMixin.methods.agentService(response.midwife),
					},
				};

				espacePartenaireStore()
					.generateDocument(payload)
					.then((res) => {
						if (res) {
							toast.add({ severity: 'success', summary: 'Message', detail: "Votre document a été généré avec succès. Veuillez l'imprimer dans le nouvel onglet!", life: 8000 });

							generationLaoding.value = false;
							media_url.value = res['document_url'];
						} 
						else
							toast.add({ severity: 'error', summary: 'Message', detail: "Désole.Le document n'a pas été bien généré", life: 8000 });

					})
					.finally(() => {
						generationLaoding.value = false;
					});
	}

	const openUrl = () => {
		window.open(media_url.value, "_blank");
	};
	const goToHome = (type: boolean) => {
		if (type) {
			router.push({ path: "/accueil" });
		} else {					
			midwife_select.value = {};
			secret_code.value = "",
			mother_first_name.value = "",
			mother_last_name.value = "",
			mother_phone_number.value = "",
			other_contact_1.value = "",
			other_contact_2.value = "",
			birthdate.value = " ",
			birth_hour.value = " ",
			sex.value = " ",
			neighborhood.value = "",
			address.value = "",

			submitted.value = false;
			mediaName.value = null;
			mediaUri.value = "";
		}
	};

</script>

<script lang="ts">
	import { defineComponent } from "vue";
	import PInput from "@/components/forms/p-input.vue";
	import { BirthDeclaration } from "@/models/declaration/birth-declaration";
	import { useAuthStore } from "@/store/modules/auth";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { useBirthStore } from "@/store/modules/birth";
	import { agentStore } from "@/store/modules/agent";
	import { AgentOption } from "@/models/agent/agent";
	import { birthHtmlTemplate, birthCssTemplate } from "@/app.config";
	import { dataMixin } from "@/mixins/data.mixins";
	import { dateMixin } from "@/mixins/date.mixins";
	
	export default defineComponent({
		name: "BirthPage",
		components: { PInput },
		mixins: [dataMixin, dateMixin],
		data() {
			const minDate = new Date();
			minDate.setDate(minDate.getDate() - 44);

			const maxDate = new Date();
			return {
				maxDate: maxDate,
				minDate: minDate,
				autoFilteredValue: [] as Array<object>,
				authStore: useAuthStore(),
				partnerStore: espacePartenaireStore(),
				agentStore: agentStore(),
			};
		},
		methods: {
			
			searchUser(event) {
				if (!event.query.trim().length) {
					this.autoFilteredValue = [...this.agentStore.agentOptions];
				} else {
					this.autoFilteredValue = this.agentStore.agentOptions.filter((user) => {
						return user.name.toLowerCase().includes(event.query.toLowerCase());
					});
				}
			},
			loadAgents() {
				if(!this.agentStore.getAgents) this.agentStore.loadAgents();
			}
		},
		beforeMount() {
			this.loadAgents()
		},
	});
</script>
<style lang="scss" src="./birth-page.scss" scoped />
