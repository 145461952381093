<template>
	<div
		class="spinner-container">
		<ProgressSpinner
			style="width: 50px; height: 50px"
			strokeWidth="5"
			fill="var(--surface-ground)"
			animationDuration=".5s"
			aria-label="Custom ProgressSpinner" />
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import { useAuthStore } from "@/store/modules/auth";

	export default defineComponent({
		name: "MainPage",

		data() {
			return {
				authStore: useAuthStore(),
			};
		},

    mounted() {
      const baseUrl: string = window.location.origin;
      const redirect: string = (baseUrl +
          this.$route.query.redirect) as string;
      this.authStore.login(redirect);
    },

  });
</script>

<style src="./main-page.scss" lang="scss" scoped />
