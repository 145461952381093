import { Agent } from "@/models/agent/agent";
import { agentStore } from "@/store/modules/agent";
export const dataMixin = {
    methods: {
        agentName(payload: string): string | undefined {
            if (agentStore().agents) {
                const agent = agentStore().agents?.find(
                    (element: Agent) => element.agent_id === payload
                );
                return agent?.name;
            } else return "";
        },
        agentService(payload: string): string | undefined {
            if (agentStore().agents) {
                const agent = agentStore().agents?.find(
                    (element: Agent) => element.agent_id === payload
                );
                return agent?.service;
            } else return "";
        },
        agentServiceId(payload: string): string | undefined {
            if (agentStore().agents) {
                const agent = agentStore().agents?.find(
                    (element: Agent) => element.agent_id === payload
                );
                return agent?.service_id;
            } else return "";
        },
        agentFunction(payload: string): string | undefined {
            if (agentStore().agents) {
                const agent = agentStore().agents?.find(
                    (element: Agent) => element.agent_id === payload
                );
                return agent?.function;
            } else return "";
        },
        partnerStatutTypeFormat(payload: string): string {
            switch (payload) {
                case "pending":
                    return "En attente";
                case "done":
                    return "Traité";
                case "rejected":
                    return "Rejeté";
                case "no_continuation":
                    return "Sans suite";
                default:
                    return "Statut inconnu";
            }
        },
    },
}