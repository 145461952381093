<template>
      <div class="pwa-container" v-if="smallScreen">
        <div class="pwa-row">
          <div class="pwa-col">
            <Message v-if="deferredPrompt" severity="info" class="m-0">
              Installez l’application de la marie Golfe 3 en quelques secondes !
              <div class="pwa-btn-container">
                <Button class="pwa-btn dismiss-btn" label="Annuler" @click="dismiss" />
                <Button class="pwa-btn install-btn" label="Installer" @click="install" />
              </div>
            </Message>
          </div>
        </div>
      </div>
</template>
  
<script lang="ts">
  import { defineComponent, ref, onMounted, onBeforeMount } from 'vue';
  
  export default defineComponent({
    setup() {
      const deferredPrompt = ref<any>(null);
      const smallScreen = ref(false);
  
      const dismiss = () => {
        deferredPrompt.value = null;
      };
  
      const install = () => {
        if (deferredPrompt.value) {
          deferredPrompt.value.prompt();
        }
      };
  
      onMounted(() => {
        if (typeof window !== "undefined") {
          window.addEventListener("beforeinstallprompt", (e: any) => {
            e.preventDefault();
            deferredPrompt.value = e;
          });
  
          window.addEventListener("appinstalled", () => {
            deferredPrompt.value = null;
          });
        }
      });
  
      onBeforeMount(() => {
        if (window.innerWidth <= 768) {
          smallScreen.value = true;
        }
      });
  
      return {
        deferredPrompt,
        smallScreen,
        dismiss,
        install,
      };
    },
  });
</script>
  
<style lang="scss" scoped>
  .pwa-container {
    padding: 20;
  }
  
  .pwa-row {
    display: flex;
    flex-direction: column;
  }
  
  .pwa-col {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .pwa-btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .pwa-btn {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    font-size: 1rem;
    letter-spacing: .15rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .dismiss-btn {
    color: black;
  }
  
  .install-btn {
    color: white;
    background-color: blue;
  }
</style>  