<template>
	<div>
		<birth-register-modal
			:data="data"
			title="Déclaration de naissance"
			:showModal="showModal"
			v-if="showModal"
			@closeModal="closeModal" />
		<div class="my-3 flex grid w-full">
			<table-filter
				:filterItems="processFilter"
				:canCreate="false"
				v-on:searchText="searchText"
				path="/declaration-naissance"
				name="une naissance" />
		</div>
		<table-view
			:data="birthStore.getBirths"
			:columns="columns"
			:tableTitle="`Liste de déclaration des naissances`"
			@onTableRowClicked="showBirthRegisterDetail" />
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import TableFilter from "@/components/table/table-filter/table-filter.vue";
	import TableView from "@/components/table/table-view/table-view.vue";
	import BirthRegisterModal from "../../modals/birth-register-modal/birth-register-modal.vue";
	import { useBirthStore } from "@/store/modules/birth";
	export default defineComponent({
		name: "BirthRegistration",
		components: { TableFilter, TableView, BirthRegisterModal },
		data() {
			return {
				columns: [
					{ field: "tracked_code", header: "Code de déclaration" },
					{ field: "health_center", header: "Centre de santé" },
					{ field: "created_at", header: "Date de déclaration" },
					{ field: "mother_last_name", header: "Nom de la mère" },
					{ field: "declarant", header: "Déclarant" },
					{ field: "document", header: "Document" },
				],
				showModal: false,
				data: null,
				birthStore: useBirthStore(),
			};
		},
		methods: {
			showBirthRegisterDetail(payload: any) {
				this.birthStore.setBirth(payload);
				this.data = payload;
				this.showModal = true;
			},
			closeModal() {
				this.showModal = !this.showModal;
			},
		},
	});
</script>
