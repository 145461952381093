<template>
	<div
		class="spinner-container">
		<ProgressSpinner
			style="width: 50px; height: 50px"
			strokeWidth="5"
			fill="var(--surface-ground)"
			animationDuration=".5s"
			aria-label="Custom ProgressSpinner" />
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
  import { useAuthStore } from "@/store/modules/auth";

  export default defineComponent({
		name: "LoginCallback",

    data() {
      return {
        authStore: useAuthStore(),
      };
    },

    created() {
      this.authStore.onLoginCallback().then(() => {
        this.$router.push("/accueil");
      });
    },

	});
</script>

<style src="./login-callback.scss" lang="scss" scoped />
