import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-3 flex grid w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_death_register_modal = _resolveComponent("death-register-modal")!
  const _component_table_filter = _resolveComponent("table-filter")!
  const _component_table_view = _resolveComponent("table-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_death_register_modal, {
          key: 0,
          data: _ctx.data,
          title: "Déclaration de décès",
          showModal: _ctx.showModal,
          onCloseModal: _ctx.closeModal
        }, null, 8, ["data", "showModal", "onCloseModal"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_table_filter, {
        filterItems: _ctx.processFilter,
        canCreate: false,
        onSearchText: _ctx.searchText,
        path: "/declaration-deces",
        name: "un décès"
      }, null, 8, ["filterItems", "onSearchText"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_table_view, {
        data: _ctx.deathStore.getDeaths,
        columns: _ctx.columns,
        filters: _ctx.filters,
        tableTitle: `Liste de déclaration des décès`,
        onOnTableRowClicked: _ctx.showDeathRegisterDetail
      }, null, 8, ["data", "columns", "filters", "onOnTableRowClicked"])
    ])
  ]))
}