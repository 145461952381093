import { ApiResponse } from "@/models/http/ApiResponse";
import { HttpBaseService } from "./httpBase.service";
import { documentGeneratorApiUrl } from "@/app.config"
import { Document } from "@/models/document/document";

export class DocumentGeneratorService extends HttpBaseService{

    private static classInstance?: DocumentGeneratorService
    static apiBaseUrl = documentGeneratorApiUrl;

    constructor(token:string) {
        super(token, DocumentGeneratorService.apiBaseUrl)
    }

    public static getInstance(token:string): DocumentGeneratorService {

        if(!this.classInstance) {
            this.classInstance = new DocumentGeneratorService(token)
        }

        return this.classInstance
    }

    public async generateDocument(payload: Document): Promise<ApiResponse<Document>> {
        const apiResponse = new ApiResponse<Document>();
        return this.instance.post('/generate', payload)
          .then(response => {
              apiResponse.data = response?.data;
              return apiResponse;
          })
          .catch(error => {
            apiResponse.apiError = error.response?.data;
            return apiResponse;
          });
    }

    public async loadFileUri(payload: string | undefined): Promise<ApiResponse<any>> {
        const apiResponse = new ApiResponse<any>();
        return await this.instance
          .get(`/load?document=${payload}`)
          .then((response) => {
            apiResponse.data = response.data;
            return apiResponse;
          })
          .catch((error) => {
            apiResponse.apiError = error.response.data;
            return apiResponse;
          });
      }
}