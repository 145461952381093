<template>
	<Dialog
		:visible="showModal"
		modal
		:style="{ width: '75rem' }"
		:breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
		<template #container>
			<div
				class="flex flex-wrap align-items-center justify-content-between gap-2 p-dialog-header">
				<span class="text-xl text-700 font-semibold">{{ title }}</span>
				<Button
					@click="closeModal"
					icon="pi pi-times"
					rounded
					text
					raised />
			</div>
			<div class="p-dialog-content">
				<div class="header pr-2">
					<div>
						<div class="separator"></div>
						<div class="flex justify-content-end">
							Enregistré depuis :
							<Badge
								pill
								severity="warning"
								>{{
									new Date(
										birthStore.birthSelected.created_at
									).toLocaleString("fr-FR", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})
								}}
							</Badge>
						</div>
					</div>
				</div>
				<div class="form-section">
					<div class="section-header">Informations du centre de santé</div>
					<div
						class="formgrid grid mx-2"
						style="margin-top: 2rem">
						<div class="field col">
							<PInput
								label="Centre de santé"
								disabled
								:value="birthStore.birthSelected.health_center" />
							<PInput
								label="Agent de santé"
								:value="agentName(birthStore.birthSelected.midwife)"
								disabled />
						</div>
						<div class="field col">
							<PInput
								label="Déclarant"
								:value="agentName(birthStore.birthSelected.declarant)"
								disabled />
							<PInput
								label="Fonction de l'agent"
								:value="birthStore.birthSelected.agent_function"
								disabled />
						</div>
					</div>
				</div>
				<div class="form-section">
					<div class="section-header">Informations sur naissance</div>
					<div
						class="formgrid grid mx-2"
						style="margin-top: 2rem">
						<div class="field col">
							<PInput
								label="Nom de la mère"
								:value="birthStore.birthSelected.mother_last_name"
								disabled />
							<PInput
								label="Téléphone de la mère"
								:value="birthStore.birthSelected.mother_phone_number"
								disabled />
							<PInput
								label="Autre contact 1"
								:value="birthStore.birthSelected.other_contact_1"
								disabled />
							<PInput
								label="Autre contact 2"
								:value="birthStore.birthSelected.other_contact_2"
								disabled />
							<PInput
								label="Quartier de la mère"
								:value="birthStore.birthSelected.neighborhood"
								disabled />
						</div>
						<div class="field col">
							<PInput
								label="Prénoms de la mère"
								:value="birthStore.birthSelected.mother_first_name"
								disabled />
							<PInput
								label="Heure d'accouchement"
								:value="birthStore.birthSelected.birth_hour"
								disabled />
							<PInput
								label="Date d'accouchement"
								:value="formattedBirthDate"
								disabled />
							<PInput
								label="Adresse de la mère"
								:value="birthStore.birthSelected.address"
								disabled />
						</div>
					</div>
				</div>
				<!-- <div class="form-section">
							<div class="section-header">Document justificatif</div>
					<template #section-inner>
					Attestation de naissance: <a class="link" :href="getBirth.file_uri" target= '_blank'> {{ getBirth.file_name }}</a>
					</template>
					</div> 
				</div> -->
			</div>
		</template>
	</Dialog>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import PInput from "@/components/forms/p-input.vue";
	import { useBirthStore } from "@/store/modules/birth";
	import { agentStore } from "@/store/modules/agent";
	import { dataMixin } from "@/mixins/data.mixins";
	import { dateMixin } from "@/mixins/date.mixins";
	export default defineComponent({
		name: "BirthRegisterModal",
		props: ["title", "showModal", "data"],
		mixins: [dataMixin, dateMixin],
		components: {
			PInput,
		},
		data() {
			return {
				birthStore: useBirthStore(),
				agentStore: agentStore(),
				formattedBirthDate: "",
				midwife: "" as string,
				declarant: "" as string,
			};
		},
		setup(props, { emit }) {
			const closeModal = () => {
				emit("closeModal");
			};

			return {
				closeModal,
			};
		},
		beforeMount() {
			this.formattedBirthDate = this.formatDate(
				this.birthStore.birthSelected.birthdate
			);
		},
	});
</script>

<style src="./birth-register-modal.scss" lang="scss" scoped />
