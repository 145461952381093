<template>
	<div class="grid justify-content-center">
		<div
			class="sm:col-6 md:col-4 lg:col-4"
			style="display: flex; justify-content: center"
			v-for="cardObject in cards"
			:key="cardObject.id">
			<AppCard
				:cardObject="cardObject"
				:iconName="cardObject.iconN"
				:applabelname="cardObject.labelName"
				:link="cardObject.link" />
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import AppCard from "../card/card.vue";

	export default defineComponent({
		components: { AppCard },
		name: "DeclarationsList",
		props: {
			cards: {
				type: Array as any,
				required: true,
			},
		},
	});
</script>
