import { defineStore } from "pinia";
import { BirthDeclaration } from "@/models/declaration/birth-declaration";
import { ProcessManagerService } from "@/services/process-manager.service";
import { useAuthStore } from "@/store/modules/auth";

export const useBirthStore = defineStore("birth", {
  state: () => ({
    births: null as BirthDeclaration[] | null,
    birthSelected: undefined as unknown as BirthDeclaration,
  }),

  getters: {
    getBirths: (state) => state.births,
    getBirth: (state) => state.birthSelected,
  },

  actions: {
    sendBirthDeclaration(payload: Array<BirthDeclaration>): Promise<any> {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken)
        .sendBirthDeclaration(payload)
        .then((value) => {
          if (value.data) {
            this.births?.unshift(value.data[0]);
            return value.data[0];
          } else return null;
        });
    },
    loadBirthDeclarations() {
      const health_center_code = useAuthStore().getUserInfo.health_center_code;
      ProcessManagerService.getInstance(useAuthStore().getAccessToken)
        .loadBirthDeclarations(health_center_code)
        .then((value) => {
          if (value.data) {
            this.births = value.data;
          }
        });
    },
    setBirth(payload: BirthDeclaration) {
      this.birthSelected = payload;
    },
  },
});
