import { defineStore} from "pinia";
import { Service, ServiceOption } from "@/models/service/service";
import { DocumentGeneratorService } from "@/services/document-generator.service";
import { ProcessManagerService } from "@/services/process-manager.service";
import { useAuthStore } from "@/store/modules/auth";
import { HealthCenter } from "@/models/health-center/health-center";

export const espacePartenaireStore = defineStore("espacePartenaire", {
  state: () => ({
    healthCenter: undefined as unknown as HealthCenter,
    services: [] as Service[],
    serviceOptions: [] as ServiceOption[],
  }),

  getters: {
    getHealthCenter: (state) => state.healthCenter,
    getServices: (state) => state.services,
  },

  actions: {
    loadServices(): any {
      const health_center_code = useAuthStore().getUserInfo.health_center_code;
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken).loadServices(health_center_code)
          .then(response => {
            if (response.data) {
              this.services = response.data;
              this.formatServices(response.data)
            }
          })
    },
    formatServices(services: Service[]) {
      services.map((service: Service) => {
        this.serviceOptions.push({
          name: service.service_name,
          id: service.service_id,
        });
      });
    },
    generateDocument(payload) {
      return DocumentGeneratorService.getInstance(useAuthStore().getAccessToken).generateDocument(payload)
          .then(value => {
            if (value) {
              return value['_data'];
            } else {
              return null;
            }
          })
    },
    getHealthCenterInformations(payload) {
      ProcessManagerService.getInstance(useAuthStore().getAccessToken).getHealthCenterInformations(payload)
          .then(response => {
              if (response.data) {
                this.healthCenter = response.data;
              }
          })
    },
    addNewService(payload: Service) : any {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken).createService(payload)
        .then((value) => {
          if (value.data) {
            this.services.unshift(value.data)
            return value.data
          }
        });
    },
    loadFileUri(payload: string | undefined) {
      DocumentGeneratorService
      .getInstance(useAuthStore().getAccessToken)
      .loadFileUri(payload)
      .then((response) => {
        if (response.data) {
          window.open(response.data.document_url, '_blank');
          return response
        }
        if (response.apiError) {
          console.log('error');
        }
      });
    },
  }
})