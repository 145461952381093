import { User, UserManager } from "oidc-client-ts";

export default class AuthService {
  private _userManager: UserManager;

  constructor(_userManager: UserManager) {
    this._userManager = _userManager;
  }

  public getProfile(): Promise<any | null> {
    return new Promise((resolve, reject) => {
      this._userManager
        .getUser()
        .then((user: User | null) => {
          if (user === null) {
            resolve(null);
          }
          resolve(user);
        })
        .catch((error) => reject(error));
    });
  }
}
