import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginPage from "@/views/login-page/login-page.vue";
import AccueilPage from "@/views/accueil-page/accueil-page.vue";
import AdminPage from "@/views/admin-page/admin-page.vue";
import BirthPage from "@/views/birth-page/new-declaration.vue";
import ListeNaissances from "@/views/birth-page/list-declaration.vue";
import DeathPage from "@/views/death-page/new-declaration.vue";
import ListeDeces from "@/views/death-page/list-declaration.vue";
import AgentsPage from "@/views/agents-page/agents-page.vue";
import LayoutComponent from "@/components/layout/layout.vue";
import { useAuthStore } from "@/store/modules/auth";
import LoginCallback from "@/views/login-callback/login-callback.vue";
import MainPage from "@/views/main-page/main-page.vue";
import SilentRenew from "@/views/silent-renew/silent-renew.vue";

const routes: Array<RouteRecordRaw> = [
  { path: "/", name: "home", redirect: "/accueil" },
  {
    path: "/main",
    name: "Main",
    component: MainPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login-callback",
    name: "login-callback",
    component: LoginCallback,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/silent-renew.html",
    name: "silent-renew",
    component: SilentRenew,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/layout",
    name: "LayoutComponent",
    component: LayoutComponent,
    children: [
      {
        path: "/accueil",
        name: "AccueilPage",
        component: AccueilPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/infos-generales",
        name: "AdminPage",
        component: AdminPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/declaration-naissance",
        name: "BirthPage",
        component: BirthPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/liste-naissances",
        name: "ListeNaissances",
        component: ListeNaissances,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/declaration-deces",
        name: "DeathPage",
        component: DeathPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/liste-deces",
        name: "ListeDeces",
        component: ListeDeces,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/agents",
        name: "AgentsPage",
        component: AgentsPage,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const connectedUser = await useAuthStore().getConnectedUser();
  if (to.meta.requiresAuth && connectedUser) {
    useAuthStore().setConnectedUser(connectedUser);
    next();
  } else if (to.meta.requiresAuth && !connectedUser) {
    const redirectUri = redirect(to);
    next({ path: "/login", query: { redirect: redirectUri } });
  } else {
    next();
  }
});

const redirect = (to: any) => {
  if (to.fullPath) {
    return to.fullPath;
  }
  return "/accueil";
};

export default router;
