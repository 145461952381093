<template>
	<div class="layout-wrapper">
		<NavbarApp ></NavbarApp>
		<div class="layout-main-container">
			<div class="router-view-container layout-main">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavbarApp from '@/components/layout/navbar-app/navbar-app.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavbarApp,
  },
});
</script>

<style lang="scss" src="./layout.scss" scoped/>