<template>
	<div class="field">
		<div class="grid px-2 align-items-center">
			<label
				:for="label"
				class="col-12 mb-2 md:col md:mb-0"
				>{{ label }}</label
			>
			<div class="col-12 md:col">
				<input
					v-model="internalValue"
					@input="updateValue"
					:placeholder="placeholder"
					:id="label"
					:type="type ? type : 'text'"
					:autocomplete="autocomplete"
					:disabled="disabled"
					class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
			</div>
		</div>
		<div
			v-if="errorMessage"
			class="flex justify-content-end px-2 error">
			{{ errorMessage }}
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, ref, watch } from "vue";
	import { useField } from "vee-validate";

	export default defineComponent({
		name: "PInput",
		props: {
			label: String,
			labelClass: String,
			inputClass: String,
			placeholder: String,
			autocomplete: String,
			type: String,
			disabled: Boolean,
			value: String,
			field: {
				type: String,
				default: () => "",
			},
			rules: {
				type: Object,
				default: () => ({}),
			},
		},
		setup(props, { emit }) {
			const internalValue = ref(props.value);
			const field = props.field as string;
			const { value: validationField, errorMessage } = useField(
				field,
				props.rules
			);

			const updateValue = () => {
				emit("update:modelValue", internalValue.value);
			};

			watch(internalValue, () => {
				emit("update:modelValue", internalValue.value);
				validationField.value = internalValue.value;
			});

			return {
				internalValue,
				updateValue,
				errorMessage,
			};
		},
	});
</script>

<style src="./p-input.scss" lang="scss" scoped />
