export const constantsMixin = {
    data() {
        return {
            naissanceList: [
                {
                    id: "11",
                    link: "/declaration-naissance",
                    externalLink: false,
                    title: "douze",
                    iconN:
                        "https://openmairie.blob.core.windows.net/cdn/pediatrics.png",
                    labelName: "Déclarer une naissance",
                },
                {
                    id: "12",
                    link: "/liste-naissances",
                    externalLink: false,
                    title: "treize",
                    iconN: "https://openmairie.blob.core.windows.net/cdn/list.png",
                    labelName: "Liste des déclarations",
                },
            ],
            decesList: [
                {
                    id: "11",
                    link: "/declaration-deces",
                    externalLink: false,
                    title: "treize",
                    iconN: "https://openmairie.blob.core.windows.net/cdn/tomb.png",
                    labelName: "Déclarer un décès ",
                },
                {
                    id: "12",
                    link: "/liste-deces",
                    externalLink: false,
                    title: "treize",
                    iconN: "https://openmairie.blob.core.windows.net/cdn/list.png",
                    labelName: "Liste des déclarations",
                },
            ],
        };
    },
}