<template>
	<div
		class="spinner-container">
		<ProgressSpinner
			style="width: 50px; height: 50px"
			strokeWidth="5"
			fill="var(--surface-ground)"
			animationDuration=".5s"
			aria-label="Custom ProgressSpinner" />
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
  import { useAuthStore } from "@/store/modules/auth";

  export default defineComponent({
		name: "SilentRenew",

    data() {
      return {
        authStore: useAuthStore(),
      };
    },

    created() {
      this.authStore.onSilentRenewCallback()
          .then(() => {
            console.log("Silent renew callback completed")
          })
          .catch((error) => {
            console.error("Silent renew callback failed", error);
          });
    },

	});
</script>

<style src="./silent-renew.scss" lang="scss" scoped />
