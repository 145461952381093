<template>
  <div class="page-container">
    <div>
      <div class="title-padding m-0">
        <h1 class="espace-header">Liste des déclarations de naissance</h1>
      </div>
      <div>
        <birth-registration />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BirthRegistration from "@/components/espace-partenaires/pages-block/birth-registration/birth-registration.vue";
import { useBirthStore } from "@/store/modules/birth";
import { useAuthStore } from "@/store/modules/auth";
import { agentStore } from "@/store/modules/agent";
export default defineComponent({
  name: "ListeNaissances",
  components: {
    BirthRegistration,
  },
  data() {
    return {
      birthStore: useBirthStore(),
      authStore: useAuthStore(),
      agentStore: agentStore(),
    };
  },
  methods: {
    loadData() {
      if (!this.agentStore.getAgents) this.agentStore.loadAgents();
      if (!this.birthStore.births) this.birthStore.loadBirthDeclarations();
    },
  },
  beforeMount() {
    this.loadData();
  },
});
</script>
