export const dateMixin = {
    methods: {
        formatDate(value: Date): string{
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleString("fr-FR", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        },
        formatLocaleDate(value: Date): string{
            const data = new Date(value).toLocaleString("fr-FR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            })
            return data
        },
        resetTimeToZero(value: Date) {
            const date = new Date(value)
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
}