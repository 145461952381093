import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppCard = _resolveComponent("AppCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (cardObject) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "sm:col-6 md:col-4 lg:col-4",
        style: {"display":"flex","justify-content":"center"},
        key: cardObject.id
      }, [
        _createVNode(_component_AppCard, {
          cardObject: cardObject,
          iconName: cardObject.iconN,
          applabelname: cardObject.labelName,
          link: cardObject.link
        }, null, 8, ["cardObject", "iconName", "applabelname", "link"])
      ]))
    }), 128))
  ]))
}