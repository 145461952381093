<template>
	<div class="flex justify-content-center">
		<div
			@click="toggle"
			aria-haspopup="true"
			aria-controls="overlay_menu"
			class="w-full p-link flex align-items-center">
			<div class="flex align">
				<span class="font-bold">{{ username }}</span>
				<span class="material-icons">arrow_drop_down</span>
			</div>
		</div>
		<Menu
			ref="menu"
			id="overlay_menu"
			class="user-navbar-overlay-menu"
			:popup="true">
			<template #end>
				<button
					class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround"
					v-if="currentRouteName == workingSpace">
					<i class="pi pi-sign-out" />
					<span class="ml-2">Mon profil</span>
				</button>
				<button
					class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround"
					@click.prevent="onLogout">
					<i class="pi pi-sign-out" />
					<span class="ml-2">Se déconnecter</span>
				</button>
			</template>
		</Menu>
	</div>
</template>

<script lang="ts">
	import { defineComponent, ref } from "vue";
	import { useAuthStore } from "@/store/modules/auth";

	export default defineComponent({
		name: "UserDropdown",
		props: {
			workingSpacePath: {
				type: String,
				required: true,
			},
			username: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				authStore: useAuthStore(),
			};
		},
		computed: {
			currentRouteName(): string {
				return "this.$route.name!.toString()";
			},
		},
		methods: {
			toggle(event: Event) {
				this.menu.toggle(event);
			},
			onLogout() {
				this.authStore.logout();
			},
		},
		setup() {
			const menu = ref();
			return {
				menu,
			};
		},
	});
</script>

<style>
	#overlay_menu.user-navbar-overlay-menu {
		margin-top: 0.7rem;
	}
</style>
