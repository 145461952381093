<template>
	<div>
		<PwaButton />
		<router-view />
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import PwaButton from "@/components/pwa-button/pwa-button.vue";

	export default defineComponent({
		components: {
			PwaButton,
		},
	});
</script>

<style lang="scss">
	@import "./common/style/app.scss";
</style>
