const HOME = "working-space";
const PROFIL = "mon-profil";
const PROFIL_PATH = "/profil";
const WORKINGSPACE_PATH = "/accueil";
const STRUCTURE_NAME = "SI MAIRIE"
const SITE_NAME = "HOSPI CONNECT"

export default {
  HOME,
  PROFIL,
  PROFIL_PATH,
  WORKINGSPACE_PATH,
  STRUCTURE_NAME,
  SITE_NAME
};