<template>
	<div class="w-full">
		<Card>
			<template #content>
				<div
					v-if="path"
					class="mt-4">
					<Button
						class="files-btn-class"
						@click="$router.push({ path: path })">
						Déclarer {{ name }}
					</Button>
				</div>
				<div
					v-else
					class="mt-4">
					<Button
						class="files-btn-class"
						@click="declaration()">
						Déclarer {{ name }}
					</Button>
				</div>
			</template>
		</Card>
	</div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: ["path", "name"],
	})
</script>

<script setup lang="ts">
	import { ref, defineEmits } from "vue";

	const emits = defineEmits(["searchText", "declarationForm"]);

	const declarationForm = ref(false);

	const declaration = () => {
		emits("declarationForm", !declarationForm.value);
		declarationForm.value = !declarationForm.value;
	};
</script>

<style scoped src="./table-filter.scss" lang="scss" />
