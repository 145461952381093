import axios, { AxiosInstance } from "axios";
import { useAuthStore } from "@/store/modules/auth";
import router from "@/router";

export abstract class HttpBaseService {
  protected instance: AxiosInstance;
  protected token: string;
  protected readonly baseUrl: string;

  protected constructor(token: string, baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: baseUrl,
    });
    this.token = token;

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest);
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const authStore = useAuthStore();
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const user = await authStore.onSignInSilent();
            if (!user) {
              throw new Error("User not found");
            }
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + user.access_token;
            return this.instance(originalRequest);
          } catch (error) {
            console.log("Error while refreshing token", error);
            authStore.removeUserData().then(() => router.push({ path: "/" }));
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );
  };

  private handleRequest = (config) => {
    if (config.headers != undefined) {
      config.headers["Authorization"] = `Bearer ${this.token}`;
    }
    return config;
  };

  private handleResponse = (response) => {
    return response;
  };
}
