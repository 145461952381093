<template>
  <div class="page-container">
    <Toast />
    <div>
      <div class="spinner-container" v-if="isLoading">
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="5"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          aria-label="Custom ProgressSpinner"
        />
      </div>
      <div class="spinner-container" v-if="generationLaoding">
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="5"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          aria-label="Custom ProgressSpinner"
        />
        <br />
        <div class="generation-text mx-3">
          <span>Votre attestation est en cours de génération.</span> <br />
          Veuillez patienter pour le télécharger.
        </div>
      </div>
      <div class="title-padding m-0">
        <div @click="$router.go(-1)" class="flex arrow_back_div mb-4">
          <span class="material-icons arrow_back_class mr-2">arrow_back</span>
          <span class="form-header">Retour</span>
        </div>
        <h1 class="espace-header">Déclaration de décès</h1>
      </div>
      <div class="form-header">
        Ce formulaire doit être rempli par un professionnel de la santé affilié
        à la télédéclaration
      </div>
      <div v-if="!submitted">
        <form @submit="onSubmit">
          <div class="form-section">
            <div class="section-header">Informations du centre de santé</div>
            <div class="formgrid grid mx-2" style="margin-top: 2rem">
              <div class="field col">
                <PInput
                  label="Centre de santé"
                  :value="useAuthStore().getUserInfo.health_center"
                />
                <div class="field grid px-2">
                  <label for="chef" class="col-12 mb-2 md:col md:mb-0"
                    >Chef service</label
                  >
                  <div class="col-12 md:col" style="text-align: end">
                    <AutoComplete
                      class="w-full"
                      placeholder="Selectionnez..."
                      id="id"
                      :dropdown="true"
                      :multiple="false"
                      v-model="chief_service_select"
                      :suggestions="autoFilteredValue"
                      @complete="searchUser($event)"
                      field="name"
                    />
                    <small id="type-help" class="p-error">
                      {{ errors.chief_service_id }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="field col">
                <div class="field grid px-2">
                  <label for="declarant" class="col-12 mb-2 md:col md:mb-0"
                    >Déclarant</label
                  >
                  <div class="col-12 md:col" style="text-align: end">
                    <AutoComplete
                      class="w-full"
                      placeholder="Selectionnez..."
                      id="value"
                      :dropdown="true"
                      :multiple="false"
                      v-model="declarant_select"
                      :suggestions="autoFilteredValue"
                      @complete="searchUser($event)"
                      field="name"
                    />
                    <small id="type-help" class="p-error">
                      {{ errors.declarant }}
                    </small>
                  </div>
                </div>
                <PInput
                  label="Code secret"
                  autocomplete="off"
                  :value="secret_code"
                  v-model="secret_code"
                  :class="{ 'p-invalid': errors.secret_code }"
                  field="secret_code"
                />
              </div>
            </div>
          </div>
          <div class="form-section">
            <div class="section-header">Informations sur le demandeur</div>
            <div class="formgrid grid mx-2" style="margin-top: 2rem">
              <div class="field col">
                <PInput
                  label="Nom et prénoms du demandeur"
                  :value="applicant_name"
                  v-model="applicant_name"
                  :class="{ 'p-invalid': errors.applicant_name }"
                  field="applicant_name"
                />
                <PInput
                  label="Lien de parenté"
                  :value="applicant_relationship"
                  v-model="applicant_relationship"
                  :class="{ 'p-invalid': errors.applicant_relationship }"
                  field="applicant_relationship"
                />
              </div>
              <div class="field col">
                <PInput
                  label="N° d'identité"
                  :value="applicant_identifier"
                  v-model="applicant_identifier"
                  field="applicant_identifier"
                />
                <PInput
                  label="N° de téléphone"
                  :value="applicant_telephone"
                  v-model="applicant_telephone"
                  :class="{ 'p-invalid': errors.applicant_telephone }"
                  field="applicant_telephone"
                />
              </div>
            </div>
          </div>
          <div class="form-section">
            <div class="section-header">Informations sur le décès</div>
            <div class="formgrid grid mx-2" style="margin-top: 2rem">
              <div class="field col">
                <PInput
                  label="Nom du defunt"
                  :value="last_name_deceased"
                  v-model="last_name_deceased"
                  :class="{ 'p-invalid': errors.last_name_deceased }"
                  field="last_name_deceased"
                />
                <PInput
                  label="Age du defunt"
                  placeholder="0"
                  :value="age_deceased"
                  v-model="age_deceased"
                  :class="{ 'p-invalid': errors.age_deceased }"
                  field="age_deceased"
                />
                <PInput
                  label="Profession du defunt"
                  :value="function_of_deceased"
                  v-model="function_of_deceased"
                  :class="{ 'p-invalid': errors.function_of_deceased }"
                  field="function_of_deceased"
                />
                <PInput
                  label="Téléphone contact"
                  :value="contact_phone_number"
                  v-model="contact_phone_number"
                  :class="{ 'p-invalid': errors.contact_phone_number }"
                  field="contact_phone_number"
                />
                <PInput
                  label="Quartier du defunt"
                  :value="neighborhood"
                  v-model="neighborhood"
                  :class="{ 'p-invalid': errors.neighborhood }"
                  field="neighborhood"
                />
              </div>
              <div class="field col">
                <PInput
                  label="Prénoms du defunt"
                  :value="first_name_deceased"
                  v-model="first_name_deceased"
                  :class="{ 'p-invalid': errors.first_name_deceased }"
                  field="first_name_deceased"
                />
                <div class="field grid px-2 py-1">
                  <label for="sexe" class="col-12 mb-2 md:col md:mb-0"
                    >Genre</label
                  >
                  <div
                    class="col-12 md:col flex flex-wrap gap-3"
                    style="justify-content: end"
                  >
                    <div class="flex">
                      <RadioButton
                        inputId="male"
                        name="Masculin"
                        value="male"
                        v-model="sex"
                      />
                      <label for="male" class="ml-2">Masculin</label>
                    </div>
                    <div class="flex">
                      <RadioButton
                        inputId="female"
                        name="Feminin"
                        value="female"
                        v-model="sex"
                      />
                      <label for="female" class="ml-2">Feminin</label>
                    </div>
                    <small id="type-help" class="p-error">
                      {{ errors.sex }}
                    </small>
                  </div>
                </div>
                <div class="field grid px-2">
                  <label for="date" class="col-12 mb-2 md:col md:mb-0"
                    >Date de décès</label
                  >
                  <div class="col-12 md:col" style="text-align: end">
                    <Calendar
                      class="w-full"
                      showIcon
                      :showOnFocus="false"
                      v-model="death_date"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      dateFormat="dd/mm/yy"
                    />
                    <small id="type-help" class="p-error">
                      {{ errors.death_date }}
                    </small>
                  </div>
                </div>
                <PInput
                  label="Cause d'admission"
                  :value="reason_for_admission"
                  v-model="reason_for_admission"
                  :class="{ 'p-invalid': errors.reason_for_admission }"
                  field="reason_for_admission"
                />
                <PInput
                  label="Adresse du defunt"
                  :value="address"
                  v-model="address"
                  :class="{ 'p-invalid': errors.address }"
                  field="address"
                />
              </div>
            </div>
          </div>
          <div class="my-4 send-declaration-btn-container">
            <Toast />
            <Button
              class="send-declaration-btn"
              label="Ajouter"
              type="submit"
            />
          </div>
        </form>
      </div>
      <div v-if="submitted">
        <div class="mt-4">
          <button class="files-btn-class mr-2" @click="goToHome(true)">
            Accueil
          </button>
          <button class="files-btn-class" @click="goToHome(false)">
            Déclarer un autre décès
          </button>
        </div>
      </div>
      <div class="bg-default-green tracked_code_container" v-if="submitted">
        <div class="text-white tracked_text" v-if="!generationLaoding">
          Votre déclaration est enregistrée sous le code :
          <span class="code-color">{{ tracked_code }}</span
          >.
        </div>
        <div class="mt-4 send-declaration-btn-container">
          <button
            v-if="media_url"
            class="send-declaration-btn"
            @click="openUrl()"
          >
            Télécharger l'attestation
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";

const schema = yup.object({
  chief_service_id: yup
    .string()
    .required("Veuillez choisir le chef service")
    .label("Chef service"),
  secret_code: yup
    .string()
    .required("Le code secret est requis")
    .label("Code secret"),
  declarant: yup
    .string()
    .required("Veuillez choisir le déclarant")
    .label("Déclarant"),
  applicant_name: yup
    .string()
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, {
      message: "Le champ ne doit contenir que des lettres et des espaces",
    })
    .required("Le nom et prénom du demandeur sont requis")
    .label("Nom et prénoms du demandeur"),
  applicant_relationship: yup
    .string()
    .required("Ce champ est requis")
    .label("Lien de parenté"),
  applicant_telephone: yup
    .string()
    .required("Le numéro de téléphone est requis")
    .matches(
      /^[0-9]{8}$/,
      "Le numéro de téléphone doit contenir exactement 8 chiffres"
    )
    .label("N° de téléphone"),
  contact_phone_number: yup
    .string()
    .required("Veuillez renseigner le numero de téléphone")
    .matches(
      /^[0-9]{8}$/,
      "Le numéro de téléphone doit contenir exactement 8 chiffres"
    )
    .label("Téléphone contact"),
  last_name_deceased: yup
    .string()
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/,
      "Le champ ne doit contenir que des lettres"
    )
    .required("Le nom du defunt est requis")
    .label("Nom du defunt"),
  first_name_deceased: yup
    .string()
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/,
      "Le champ ne doit contenir que des lettres"
    )
    .required("Le prénom du defunt est requis")
    .label("Prénoms du defunt"),
  age_deceased: yup
    .string()
    .matches(/^[0-9]+$/, "L'âge du défunt doit être un nombre")
    .required("L'age du defunt est requis")
    .label("Age du defunt"),
  function_of_deceased: yup
    .string()
    .required("La fonction du defunt est requies")
    .label("Profession du defunt"),
  death_date: yup
    .string()
    .required("La date de décès est requise")
    .label("Date de décès"),
  reason_for_admission: yup
    .string()
    .required("La cause de décès est requise")
    .label("Cause d'admission"),
  sex: yup.string().required("Le genre du defunt est requis").label("Genre"),
  neighborhood: yup
    .string()
    .required("Veuillez renseigner le quartier du defunt")
    .label("Quartier du defunt"),
  address: yup
    .string()
    .required("Veuillez renseigner l'adresse du defunt")
    .label("Adresse du defunt"),
});

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: schema,
});

const [service_id] = defineField("service_id");
const [secret_code] = defineField("secret_code");
const [declarant] = defineField("declarant");
const [chief_service_id] = defineField("chief_service_id");
const [applicant_name] = defineField("applicant_name");
const [applicant_telephone] = defineField("applicant_telephone");
const [applicant_identifier] = defineField("applicant_identifier");
const [applicant_relationship] = defineField("applicant_relationship");
const [last_name_deceased] = defineField("last_name_deceased");
const [first_name_deceased] = defineField("first_name_deceased");
const [age_deceased] = defineField("age_deceased");
const [function_of_deceased] = defineField("function_of_deceased");
const [contact_phone_number] = defineField("contact_phone_number");
const [other_contact] = defineField("other_contact");
const [death_date] = defineField("death_date");
const [reason_for_admission] = defineField("reason_for_admission");
const [sex] = defineField("sex");
const [neighborhood] = defineField("neighborhood");
const [address] = defineField("address");

const chief_service_select = ref({} as AgentOption);
const declarant_select = ref({} as AgentOption);
const submitted = ref(false);
const isLoading = ref(false);
const mediaName = ref(null);
const generationLaoding = ref(false);
const tracked_code = ref("");
const media_url = ref("");
const mediaUri = ref("");
const router = useRouter();
const toast = useToast();

watch(
  () => chief_service_select.value,
  (newVal) => {
    if (newVal?.id) chief_service_id.value = newVal.id;
    else chief_service_id.value = " ";
  }
);

watch(
  () => declarant_select.value,
  (newVal) => {
    declarant.value = newVal.id;
  }
);

const onSubmit = handleSubmit((values) => {
  if (isLoading.value || new Date(values.death_date) > new Date()) {
    toast.add({
      severity: "error",
      summary: "Message",
      detail: "Veuillez renseigner une date de décès valide",
      life: 8000,
    });
    return;
  }

  isLoading.value = true;
  const deathDeclaration = values as DeathDeclaration;
  deathDeclaration.agent_function = "Agent";
  deathDeclaration.applicant_identifier = values.applicant_identifier
    ? values.applicant_identifier
    : "";
  deathDeclaration.health_center = useAuthStore().getUserInfo.health_center;
  deathDeclaration.health_center_code =
    useAuthStore().getUserInfo.health_center_code;
  deathDeclaration.agent_function = dataMixin.methods.agentFunction(
    deathDeclaration.chief_service_id
  );
  deathDeclaration.service_id = dataMixin.methods.agentServiceId(
    deathDeclaration.chief_service_id
  );
  deathDeclaration.death_date = dateMixin.methods.resetTimeToZero(
    values.death_date
  );

  const array = [] as Array<DeathDeclaration>;
  array.push(deathDeclaration);
  useDeathStore()
    .sendDeathDeclaration(array)
    .then((response) => {
      if (response) {
        submitted.value = true;
        tracked_code.value = response.tracked_code;
        generateDoc(response);
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
});

const generateDoc = (response: DeathDeclaration) => {
  generationLaoding.value = true;
  const payload = {
    html_template_name: deathHtmlTemplate,
    css_template_name: deathCssTemplate,
    document_id: response.tracked_code,
    variables: {
      health_center_name: response.health_center,
      service: dataMixin.methods.agentService(response.chief_service),
      tracked_code: response.tracked_code,
      defunt_name:
        response.last_name_deceased + " " + response.first_name_deceased,
      defunt_age: response.age_deceased,
      defunt_function: response.function_of_deceased,
      town: espacePartenaireStore().getHealthCenter.town,
      death_date: dateMixin.methods.formatDate(response.death_date),
      district: espacePartenaireStore().getHealthCenter.district,
      township: espacePartenaireStore().getHealthCenter.township,
      bp: espacePartenaireStore().getHealthCenter.postal_code,
      center_phone: espacePartenaireStore().getHealthCenter.mobile,
      created_at: dateMixin.methods.formatDate(response.created_at),
      chief_service: dataMixin.methods.agentName(response.chief_service),
      function: dataMixin.methods.agentFunction(response.chief_service),
    },
  };
  espacePartenaireStore()
    .generateDocument(payload)
    .then((res) => {
      if (res) {
        toast.add({
          severity: "success",
          summary: "Message",
          detail:
            "Votre document a été généré avec succès. Veuillez l'imprimer dans le nouvel onglet!",
          life: 8000,
        });

        generationLaoding.value = false;
        media_url.value = res["document_url"];
      } else
        toast.add({
          severity: "error",
          summary: "Message",
          detail: "Désole.Le document n'a pas été bien généré",
          life: 8000,
        });
    })
    .finally(() => {
      generationLaoding.value = false;
      isLoading.value = false;
    });
};

const openUrl = () => {
  window.open(media_url.value, "_blank");
};

const goToHome = (type: boolean) => {
  if (type) {
    router.push({ path: "/accueil" });
  } else {
    (service_id.value = ""), (chief_service_select.value = {});
    (secret_code.value = ""),
      (applicant_name.value = ""),
      (applicant_telephone.value = ""),
      (applicant_identifier.value = ""),
      (applicant_relationship.value = ""),
      (last_name_deceased.value = ""),
      (first_name_deceased.value = ""),
      (age_deceased.value = 0),
      (function_of_deceased.value = ""),
      (contact_phone_number.value = ""),
      (other_contact.value = ""),
      (death_date.value = " "),
      (reason_for_admission.value = ""),
      (sex.value = " "),
      (neighborhood.value = ""),
      (address.value = ""),
      (submitted.value = false);
    mediaName.value = null;
    mediaUri.value = "";
  }
};
</script>

<script lang="ts">
import { defineComponent } from "vue";
import PInput from "@/components/forms/p-input.vue";
import { useAuthStore } from "@/store/modules/auth";
import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
import { useDeathStore } from "@/store/modules/death";
import { agentStore } from "@/store/modules/agent";
import { AgentOption } from "@/models/agent/agent";
import { DeathDeclaration } from "@/models/declaration/death-declaration";
import { deathHtmlTemplate, deathCssTemplate } from "@/app.config";
import { dataMixin } from "@/mixins/data.mixins";
import { dateMixin } from "@/mixins/date.mixins";
export default defineComponent({
  name: "DeathPage",
  components: { PInput },
  mixins: [dataMixin, dateMixin],
  data() {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 14);

    const maxDate = new Date();
    return {
      maxDate: maxDate,
      minDate: minDate,
      autoFilteredValue: [] as Array<object>,
      authStore: useAuthStore(),
      partnerStore: espacePartenaireStore(),
      agentStore: agentStore(),
    };
  },
  methods: {
    searchUser(event) {
      if (!event.query.trim().length) {
        this.autoFilteredValue = [...this.agentStore.agentOptions];
      } else {
        this.autoFilteredValue = this.agentStore.agentOptions.filter((user) => {
          return user.name.toLowerCase().includes(event.query.toLowerCase());
        });
      }
    },
    loadAgents() {
      if (!this.agentStore.getAgents) this.agentStore.loadAgents();
    },
  },
  beforeMount() {
    this.loadAgents();
  },
});
</script>
<style lang="scss" src="./death-page.scss" scoped />
